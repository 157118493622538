.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #0736a4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

button:focus {
  outline: 0;
}

.link {
  color: #339999;
  font-weight: bold;
}
.link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.App-link {
  color: #61dafb;
}

.vcenter {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 10px;
  float: none;
}

.origins-tabs {
  display: inline-block !important;
}

.error-label {
  font-size: 13px;
  color: #ff0000;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.entry-row:not(:last-child) {
  border-bottom: 1px solid #ccc;
}

body,
html {
  overflow-x: hidden;
}

.col-center {
  margin: 0 auto;
}

div.test caption {
  padding: 40px 100px 40px 50px;
}

html,
body {
  margin: 0;
  padding: 0;
  background-color: #0736a4;
  width: 100%;
  overflow-x: hidden;
}

.disable-focus:focus {
  outline: 0;
}
.circle {
  display: block;
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
}

.plane {
  display: block;
  position: absolute;
  z-index: 2;
  height: 100%;
  width: 100%;
}

.spinner {
  animation-name: spin;
  animation-duration: 3.2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.big-spinner {
  position: absolute;
  left: calc(37.5%);
  top: calc(30%);
  width: 25%;
  height: 25%;
}

.small-spinner {
  background-color: "0736a4";
  position: absolute;
  left: calc(50% - 2em);
  top: calc(50% - 2em);
  width: 4em;
  height: 4em;
}

.small-spinner-bottom {
  background-color: "0736a4";
  position: relative;
  bottom: 0;
  width: 4em;
  height: 4em;
  margin-bottom: 1em;
}

.button-spinner {
  background-color: "0736a4";
  position: relative;
  left: calc(50% - 2em);
  top: calc(50% - 2em);
  width: 4em;
  height: 4em;
}

.card {
  box-shadow: 0px 1px 5px #555;
  height: 10rem;
  justify-content: center;
}

.school-card-container:hover {
}

.card-container {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
}

.app-footer {
  color: #ffffff;
  text-align: center;
  background-color: #0736a4;
  width: 100%;
  height: 20vh;
  z-index: 0;
}

.body-container {
  min-height: 100vh;
  min-height: 100%;
}

.base-page {
  min-height: 100vh;
  width: 100%;
}

.credit-link {
  color: white;
  text-decoration: underline;
}
.credit-link:hover {
  color: #339999;
}

.chevron-icon:hover {
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 100%;
  cursor: pointer;
}

ul.MuiList-root {
  padding: 0pt;
}

.appbar-link:hover {
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 8%;
}

.page-header {
  font-size: 2em;
  margin: 0.2em;
}

.subtitle {
  font-size: 1em;
  margin: 0 0 10px 0;
}

.scroll-to-top {
  margin: 0.8em;
  width: 2.2em;
  padding: 0.5em;
  position: sticky;
  top: 100%;
  left: 100%;
  z-index: 1;
}

.mobileHide {
  display: inline;
}

/* Smartphone Portrait and Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .mobileHide {
    display: none;
  }
}

.MuiTab-root {
  min-width: 0px !important;
}

.scroll-bounce {
  animation: bounce 0.5s cubic-bezier(0.5, 0.05, 1, 0.5);
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-play-state: running;
  animation-iteration-count: 4;
}

@keyframes bounce {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(0, 18px, 0);
  }
}

.please-confirm {
  padding: 0.3rem;
  border-color: #339999;
  border-style: solid;
  border-width: 2px;
  border-radius: 5px;
  font-size: 0.9rem;
}

.blurred-text {
  color: transparent;
  text-shadow: 0 0 12px rgba(0, 0, 0, 0.8);
}
