.dot {
  height: 2.7em;
  width: 2.7em;
  background-color: #bbb;
  position: relative;
  border-radius: 50%;
  z-index: 2;
  display: table-cell;
  text-align: center;
  top: 1.5em;
  left: -2.5em;
  border: 0.3em solid white;
  vertical-align: middle;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
}

.anchor-scroll-icon {
  height: 2em;
  bottom: 5%;
}

.dot-number {
  font-weight: 700;
  position: sticky;
  color: #ffffff;
  z-index: 3;
  font-size: 1.2em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
}

.landing-page-main-section {
  display: block;
  width: 100%;
  height: 100vh;
  min-height: 800px;
  margin: 0;
  padding: 0;
  background-color: #0736a4;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.step-container {
  color: "#000000";
}

.example-enter {
  opacity: 0.01;
  width: 0px;
}

.step-icon {
  height: 6em;
  position: relative;
  z-index: 1;
}

h2 {
  display: block;
  font-size: 2.4em;
  font-weight: bold;
}

h3 {
  display: block;
  font-size: 1.6em;
  font-weight: bold;
}

@media (min-width: 60em) .grid>* {
  margin-top: 2.618em;
  padding-left: 2.618em;
}

.new-landing-page-graphic {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100vw;
  max-height: 100vh;
  padding: 5px;
  position: relative;
  top: -8vh;
  min-height: 200px;
}

.landing-page-text {
  display: block;
  color: #ffffff;
  font-family: "Verdana";
  font-size: 15px;
}

.how-it-works {
  color: #ffffff;
  font-family: "Verdana";
  font-size: 30px;
  font-weight: 500;
  font-style: italic;
}

.save-money {
  color: #ffffff;
  font-family: "Verdana";
  font-size: 20px;
  font-weight: 600;
  font-style: italic;
}

.dotted-line {
  border: 2px dashed #ffffff;
  border-style: none none dashed;
  color: #ffffff;
  background-color: none;
}

.number-icon {
  height: 75%;
  width: 75%;
}

.graphic-icon {
  height: 100%;
  width: 100%;
}

.divider {
  height: 5%;
  width: 95%;
  display: block;
}

.row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.container-max {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.error-arrow {
  width: 90%;
}

.text-404 {
  display: block;
  color: #ffffff;
  font-family: "Verdana";
  font-size: 30px;
}

.pick-who {
  display: block;
  color: #ffffff;
  font-family: "Verdana";
  font-size: 2rem;
  font-weight: 700;
}

.yasa-portrait {
  display: block;
  width: 62%;
  margin-left: auto;
  margin-right: auto;
}

.cooper-portrait {
  display: block;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}

.button-color {
  background-color: #339999;
}

.mercy {
  display: block;
  color: #ffffff;
  font-family: "Verdana";
  font-size: 11px;
}

@media (min-width: 576px) {
  .container-max {
    width: 800px;
  }
  .number-icon {
    height: 100px;
    width: 100px;
  }
  .graphic-icon {
    height: 120px;
    width: 120px;
  }
  .new-landing-page-graphic {
    width: 100%;
  }
}

.no-padding {
  padding: 0px;
  margin: 0px;
}

.step {
  padding: 1em;
}

.steps-section {
  min-height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2em;
}

@media (min-width: 576px) {
  .container-max {
    width: 800px;
  }
  .number-icon {
    height: 100px;
    width: 100px;
  }
  .graphic-icon {
    height: 120px;
    width: 120px;
  }
  .new-landing-page-graphic {
    height: 70vh;
  }
  .steps-wrapper: {
    width: 50vw;
  }
}

@media (min-width: 768px) {
  .container-max {
    width: 800px;
  }
  .number-icon {
    height: 100px;
    width: 100px;
  }
  .graphic-icon {
    height: 120px;
    width: 120px;
  }
  .new-landing-page-graphic {
    height: 70vh;
  }
  .steps-wrapper: {
    width: 50vw;
  }
}

@media (min-width: 992px) {
  .container-max {
    width: 800px;
  }
  .number-icon {
    height: 100px;
    width: 100px;
  }
  .graphic-icon {
    height: 120px;
    width: 120px;
  }
  .new-landing-page-graphic {
    height: 70vh;
  }
  .steps-wrapper: {
    width: 20vw;
  }
}

@media (min-width: 1200px) {
  .container-max {
    width: 800px;
  }
  .number-icon {
    height: 100px;
    width: 100px;
  }
  .graphic-icon {
    height: 120px;
    width: 120px;
  }
  .new-landing-page-graphic {
    height: 70vh;
  }
}
